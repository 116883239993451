<template>

    <!-- <div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column"> -->
    <header class="">
        <div>
            <h3 class="float-md-start mb-0">hextree.io assembly thing</h3>
            <nav class="nav nav-masthead justify-content-center float-md-end">
                <div class="btn-group" role="group">
                    <button ref="previous_challenge" type="button" class="btn btn-primary"
                        id="previous-challenge-button"
                        @click="handlePreviousChallenge">
                        <i class="bi bi-arrow-left"></i>
                    </button>
                    <button ref="next_challenge" type="button" class="btn btn-primary" id="next-challenge-button" @click="handleNextChallenge">
                        <i class="bi bi-arrow-right"></i>
                    </button>
                </div>
            </nav>
        </div>
    </header>
    <hr />

    <Transition mode="out-in">
        <!-- Add the key attribute here -->
        <component :is="currentComponent" :key="challenge_index" :challenge="challenge" @start="handleStart" @challengeCompleted="handleChallengeCompleted" @showDescription="handleShowDescription" :stats="statisticsData" @nextChallenge="handleNextChallenge" @improve="handleImprove" />
    </Transition>
<!--     
    <Transition mode="out-in">
        <ChallengeOverview v-if="currentState === STATES.DOCUMENTATION" :challenge="challenge" @start="handleStart" />
        <Challenge v-else-if="currentState === STATES.CHALLENGE" :challenge="challenge" @challengeCompleted="handleChallengeCompleted" @showDescription="handleShowDescription" />
        <LevelFinish v-else-if="currentState === STATES.FINISHED" :challenge="challenge" :stats="statisticsData" @nextChallenge="handleNextChallenge" @improve="handleImprove"/>
        <p v-else>Invalid state - WHAT DID YOU DOOOOOOO?????</p>
    </Transition> -->

    <ToastWidget ref="toast" :auto-hide="true" />
    <LevelFinishModal ref="statsModal" :challenge="challenge" :stats="statisticsData" />
</template>

<script>
import MemoryWidget from './components/MemoryWidget.vue';
import CodeWidget from './components/CodeWidget.vue';
import RegisterWidget from './components/RegisterWidget.vue';
import BootstrapAlert from './components/BootstrapAlert.vue';
import ToastWidget from './components/ToastWidget.vue';
import Typewriter from './components/Typewriter.vue';
import Challenges from './Challenges.js';
import ArmEmulator from './emulator.js';
import LevelFinish from './components/LevelFinish.vue';
import LevelFinishModal from './components/LevelFinishModal.vue';
import TransitionTest from './components/TransitionTest.vue';
import ChallengeOverview from './components/ChallengeOverview.vue';
import Challenge from './components/Challenge.vue';
import StarRenderer from './components/StarRenderer.vue';

import * as util from './Util.js';

export default {
    name: 'App',
    components: {
        CodeWidget,
        MemoryWidget,
        RegisterWidget,
        ToastWidget,
        BootstrapAlert,
        Typewriter,
        LevelFinishModal,
        TransitionTest,
        ChallengeOverview,
        Challenge,
        LevelFinish,
        StarRenderer
    },
    computed: {
        currentComponent() {
            if (this.currentState === this.STATES.DOCUMENTATION) {
                return 'ChallengeOverview';
            } else if (this.currentState === this.STATES.CHALLENGE) {
                return 'Challenge';
            } else if (this.currentState === this.STATES.FINISHED) {
                return 'LevelFinish';
            } else {
                return 'InvalidComponent';
            }
        }
    },
    data() {
        return {
            challenge: null,
            challenge_index: localStorage.getItem('challenge_index') 
                ? parseInt(localStorage.getItem('challenge_index')) 
                : 0,
            statisticsData: {
                instructionCount: 0,
                executionSteps: 0
            },
            STATES: {
                DOCUMENTATION: 'DOCUMENTATION',
                CHALLENGE: 'CHALLENGE',
                FINISHED: 'FINISHED',
            },
            currentState: 'DOCUMENTATION'
        };
    },
    created() {
        console.log("created")


        console.log(Challenges)
        this.challenge = Challenges[this.challenge_index]
        this.challenge.load()
        if(this.challenge.data?.descriptionRead) {
            this.currentState = this.STATES.CHALLENGE;
        }
        // this.code = this.challenge.template
        console.log(this.challenge.description)
        console.log("created done")
    },
    mounted() {
        // console.log("Component mounted");
        // emu.map_memory(0, 0x1000)
        // console.log("Component mounted done");
    },
    methods: {
        challengeSlug(challenge) {
            return 'challenge-data-' + challenge.title;
        },

        calculateChallengeScore(stats) {
            const score = {
                instructionCountStars: 0,
                executionStepsStars: 0,
                totalStars: 0
            };
            // // Compute stars for instruction count
            const instructionCountStars = (challenge, instructionCount) => {
                if (instructionCount <= challenge.score["3"].instructionCount) return 3;
                if (instructionCount <= challenge.score["2"].instructionCount) return 2;
                return 1;
            }
            // // Compute stars for execution steps
            const executionStepsStars = (challenge, executionSteps) => {
                if (executionSteps <= challenge.score["3"].executionSteps) return 3;
                if (executionSteps <= challenge.score["2"].executionSteps) return 2;
                return 1;
            };

            score.instructionCountStars = instructionCountStars(this.challenge, stats.instructionCount);
            score.executionStepsStars = executionStepsStars(this.challenge, stats.executionSteps);
            const total = (score.instructionCountStars + score.executionStepsStars) / 2;
            score.totalStars = total;
        },
        handleStart() {
            console.log("HANDLE START")
            this.currentState = this.STATES.CHALLENGE
        },
        handleChallengeCompleted(statistics) {
            console.log("CHALLENGE COMPLETED!")
            console.log(statistics)
            this.statisticsData = statistics;
            this.currentState = this.STATES.FINISHED;
        },
        switchChallenge(newChallengeIndex) {
            this.challenge_index = newChallengeIndex
        },

        handleNextChallenge() {
            if(this.challenge_index < Challenges.length - 1) {
                // this.challenge_index = this.challenge_index + 1;
                this.switchChallenge(this.challenge_index + 1)
                this.challenge = Challenges[this.challenge_index]
                this.challenge.load()
                this.currentState = this.STATES.DOCUMENTATION
                if(this.challenge.description && !this.challenge.data?.descriptionRead) {
                    this.currentState = this.STATES.DOCUMENTATION
                } else {
                    this.currentState = this.STATES.CHALLENGE
                }
            }
        },
        handlePreviousChallenge() {
            if(this.challenge_index > 0) {
                this.challenge_index--;
                this.challenge = Challenges[this.challenge_index]
                this.challenge.load()
                this.currentState = this.STATES.DOCUMENTATION
                if(this.challenge.description && !this.challenge.data?.descriptionRead) {
                    this.currentState = this.STATES.DOCUMENTATION
                } else {
                    this.currentState = this.STATES.CHALLENGE
                }
            }
        },
        handleShowDescription() {
            this.currentState = this.STATES.DOCUMENTATION
        },
        handleImprove() {
            this.currentState = this.STATES.CHALLENGE
        }
    },
    watch: {
        challenge_index(newIndex) {
            console.log("CHALLENGE INDEX CHANGED")
            localStorage.setItem('challenge_index', newIndex);
            if (newIndex === 0) {
                this.$refs.previous_challenge.disabled = true;
            } else {
                this.$refs.previous_challenge.disabled = false;
            }
            },
            // Watch currentState and update localStorage
            currentState(newState) {
            localStorage.setItem('currentState', newState);
        },
        // challenge_index() {
        //     if (this.challenge_index == 0) {
        //         this.$refs.previous_challenge.disabled = true
        //     } else {
        //         this.$refs.previous_challenge.disabled = false
        //     }
        // }
    }
};
</script>

<style scoped>
/* Add your styles here */

.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
