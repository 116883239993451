// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Component-specific styles */
`, "",{"version":3,"sources":["webpack://./src/components/StarRenderer.vue"],"names":[],"mappings":";AA2CA,8BAA8B","sourcesContent":["<template>\n    <span v-html=\"renderStars(stars)\"></span>\n</template>\n\n<script>\n\nimport * as util from '../Util.js';\nexport default {\n\n    name: \"StarRenderer\",\n    props: {\n        totalStars: {\n            type: Number,\n            default: 3\n        },\n        stars: {\n            type: Number,\n            required: true\n        },\n    },\n    data() {\n        return {\n        };\n    },\n    methods: {\n        renderStars(stars) {\n            let result = '';\n            for (let i = 0; i < Math.floor(stars); i++) {\n                result += '<i class=\"bi bi-star-fill\"></i>';\n            }\n            if (stars % 1 !== 0) {\n                result += '<i class=\"bi bi-star-half\"></i>';\n            }\n            for (let i = Math.ceil(stars); i < this.totalStars; i++) {\n                result += '<i class=\"bi bi-star\"></i>';\n            }\n            return result;\n        },\n    },\n}\n</script>\n\n<style scoped>\n/* Component-specific styles */\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
