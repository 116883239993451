import { ChallengeBase } from "./Challenge"

export class MovRegister extends ChallengeBase {
    title = "mov - register"
    description = `The mov instruction can also copy the value of one register to another one:
    
For example, this code:
%mov r0, r1%

Will copy the value currently in r1 into r0.

In general you will find that most Arm instructions will have operand, then the destination register, and then the source register/immediate.

Now we have setup the emulator with a value in r3... Can you copy it into r0?
`

    description_short = `Copy whatever is in r3 into r0!`
    prepare(emu) {
        console.log("Preparing challenge...")
        console.log(emu)
        emu.setRegister("R3", 0x12345)
    }

    check(emu) {
        console.log("Checking for success")
        if(emu.getRegister("R0") == 0x12345) {
            return true;
        }
        return false;
    }

    template = `mov r0, #0`

    registers_in = ["R0", "R3"]
    registers_out = ["R0"]

    score = {
        "3": {
            instructionCount: 1,
            executionSteps: 0 // TODO
        },
        "2": {
            instructionCount: 3,
            executionSteps: 3 // TODO
        },
    }

    memories = []
}
