import { ChallengeBase } from "./Challenge"

export class Carry extends ChallengeBase {
    title = "carry"
    description = `asdfasdfasdfasdf asdf`

    description_short = `Add r1, r2 and r3 together into r0!`
    prepare(emu) {
        console.log("Preparing challenge...")
        console.log(emu)
        emu.setRegister("R1", 0xf0000000)
        emu.setRegister("R2", 0xf0000000)
    }

    check(emu) {
        console.log("Checking for success")
        if(emu.getRegister("R0") == 0x123 + 0x234 + 0x456) {
            return true;
        }
        return false;
    }

    template = `add r0, r0, r0`

    show_registers = true
    show_flags = true

    score = {
        "3": {
            instructionCount: 2,
            executionSteps: 0 // TODO
        },
        "2": {
            instructionCount: 3,
            executionSteps: 3 // TODO
        },
    }

    memories = []
}
