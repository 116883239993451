import { ChallengeBase } from "./Challenge"

export class ConditionalExecution extends ChallengeBase {
    title = "Conditional Execution"
    description = `Now we just used the BEQ and BNE instructions to conditionally branch... But I tricked you a bit:
    
On 32-bit Arm, EVERY instruction can be conditionally executed by being suffixed with a condition such as EQ or NE. So for example:

%
cmp r0, r1
addeq r0, #1
movne r0, #0
%

Would add 1 to r0 if r0 and r1 are equal, and otherwise set r0 to zero.

Lets try this: Write code that compares r0 and r1 - and if they are equal, set r0 to #1, and otherwise, set r0 to #0!
`
    show_flags = true
    description_short = `Compare r0 and r1. If they are equal, set r0 to 1, otherwise set r0 to 0. Try to use moveq and movne!`
    prepare(emu) {
        console.log("Preparing challenge...")
        console.log(emu)
        emu.setRegister("R0", 1)
        emu.setRegister("R1", 1)
    }

    check(emu) {
        console.log("Checking for success")
        if(emu.getRegister("R0") == 1) {
            return true;
        }
        return false;
    }


    template = ``

    // registers_in = ["R1", "R2", "R3"]
    registers_out = ["R0"]

    score = {
        "3": {
            instructionCount: 3,
            executionSteps: 0 // TODO
        },
        "2": {
            instructionCount: 4,
            executionSteps: 3 // TODO
        },
    }

    memories = []
}
