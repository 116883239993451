<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h2>{{challenge.title}}</h2>
                <table>
                    <!-- Number of instructions row -->
                    <tr>
                        <th><h3>Number of instructions:</h3></th>
                        <td style="padding-left: 10px; padding-right: 10px;">
                          <h3><span v-html="renderStars(instructionCountStars)"></span></h3>
                        </td>
                        <td style="width: 50px; text-align: center;"><h3>{{ stats.instructionCount }}</h3></td>
                    </tr>
                    <!-- Execution steps row -->
                    <tr>
                        <th><h3>Execution steps: (TODO)</h3></th>
                        <td style="padding-left: 10px; padding-right: 10px; white-space: nowrap;">
                          <h3><span v-html="renderStars(executionStepsStars)"></span></h3>
                        </td>
                        <td style="width: 50px; text-align: center;"><h3>{{ stats.executionSteps }}</h3></td>
                    </tr>

                  <tr>
                        <th><h3><strong>Total score:</strong></h3></th>
                        <td style="padding-left: 10px; padding-right: 10px;">
                          <h3><span v-html="renderStars(totalStars)"></span></h3>
                        </td>
                        <td></td>
                    </tr>
                </table>
                <button class="btn btn-secondary" @click="$emit('improve')">Improve</button>&nbsp;&nbsp;
                <button class="btn btn-primary" @click="$emit('nextChallenge')">Next challenge</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    challenge: {
      type: Object,
      required: true,
    },
    emits: [
        'nextChallenge',
        'improve'
    ],
    stats: {
      type: Object,
      required: true,
    },
  },
  computed: {
    // Compute stars for instruction count
    instructionCountStars() {
      const instructionCount = this.stats.instructionCount;
      if (instructionCount <= this.challenge.score["3"].instructionCount) return 3;
      if (instructionCount <= this.challenge.score["2"].instructionCount) return 2;
      return 1;
    },
    // Compute stars for execution steps
    executionStepsStars() {
      const executionSteps = this.stats.executionSteps;
      if (executionSteps <= this.challenge.score["3"].executionSteps) return 3;
      if (executionSteps <= this.challenge.score["2"].executionSteps) return 2;
      return 1;
    },
    // Calculate total stars based on both scores
    totalStars() {
      const total = (this.instructionCountStars + this.executionStepsStars) / 2;
      if (total === 3) return 3;
      if (total === 2.5) return 2.5;
      if (total === 2) return 2;
      if (total === 1.5) return 1.5;
      return 1;
    },
  },
  methods: {
    // Method to render stars dynamically
    renderStars(stars) {
      let result = '';
      for (let i = 0; i < Math.floor(stars); i++) {
        result += '<i class="bi bi-star-fill"></i>';
      }
      if (stars % 1 !== 0) {
        result += '<i class="bi bi-star-half"></i>';
      }
      for (let i = Math.ceil(stars); i < 3; i++) {
        result += '<i class="bi bi-star"></i>';
      }
      return result;
    },
    showModal() {
      this.$refs.modal.showModal();
    },
    closeModal() {
      this.$refs.modal.hideModal();
    },
    handleAction() {
      console.log('Action taken on statistics');
      this.closeModal();
    },
  },
};
</script>

<style scoped>
/* Add styles if needed */
</style>
