// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Add styles if needed */
.v-enter-active[data-v-92963392],
.v-leave-active[data-v-92963392] {
  transition: opacity 0.5s ease;
}
.v-enter-from[data-v-92963392],
.v-leave-to[data-v-92963392] {
  opacity: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/TransitionTest.vue"],"names":[],"mappings":";AAuBA,yBAAyB;AACzB;;EAEE,6BAA6B;AAC/B;AAEA;;EAEE,UAAU;AACZ","sourcesContent":["<template>\n    <button @click=\"show = !show\">Toggle</button>\n    <Transition mode=\"out-in\">\n        <p v-if=\"show\">hello</p>\n        <p v-else>me</p>\n    </Transition>\n</template>\n\n<script>\nexport default {\n    data() {\n        return {\n            show: false\n        }\n    },\n  computed: {\n  },\n  methods: {\n  },\n};\n</script>\n\n<style scoped>\n/* Add styles if needed */\n.v-enter-active,\n.v-leave-active {\n  transition: opacity 0.5s ease;\n}\n\n.v-enter-from,\n.v-leave-to {\n  opacity: 0;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
