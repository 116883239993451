<template>
    <div>
        <h4>Flags</h4>
      <table class="">
        <!-- <thead> -->
          <tr  v-for="(flag, index) in configuredFlags" :key="index" data-bs-toggle="tooltip" :title="flagDescriptions[flag]">
            <td style="text-align: left; padding-right: 5px;"><strong>{{ flagDescriptions[flag][0] }}</strong>{{ flagDescriptions[flag].substring(1) }}</td>
            <td>{{ cpsrFlags[flag]}}</td>
          </tr>
            <!-- <th v-for="(flag, index) in configuredFlags" :key="index" @click="showTooltip(flag)" data-bs-toggle="tooltip" :title="flagDescriptions[flag]">
              {{ flag }}
            </th>
          </tr> -->
        <!-- </thead> -->
        <!-- <tbody>
          <tr>
            <td v-for="(flag, index) in configuredFlags" :key="index" @click="showTooltip(flag)" data-bs-toggle="tooltip" :title="flagDescriptions[flag]">
              {{ cpsrFlags[flag] }}
            </td>
          </tr>
        </tbody> -->
      </table>
    </div>
  </template>
  
  <script>
  import * as bootstrap from 'bootstrap';

  export default {
    name: 'CPSR',
    props: {
      configuredFlags: {
        type: Array,
        default: ['N', 'Z', 'C', 'V']
      },
      cpsrFlags: {
        type: Object,
        required: true,
      }
    },
    data() {
      return {
        flagDescriptions: {
          N: "Negative",
          Z: "Zero",
          C: "Carry",
          V: "Overflow",
          Q: "Sticky",
          J: "Jazelle",
          GE: "Greater than or Equal",
          E: "Endianness flag",
          A: "Asynchronous abort mask (bit 8)",
          I: "IRQ disable flag (bit 7)",
          F: "FIQ disable flag (bit 6)",
          T: "Thumb state flag (bit 5)",
          M: "Processor mode (bits 4-0)"
        }
      };
    },
    methods: {
      showTooltip(flag) {
        const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
        const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
          return new bootstrap.Tooltip(tooltipTriggerEl);
        });
      }
    },
    mounted() {
      // Initialize tooltips after mounting
      this.showTooltip();
    }
  };
  </script>
  
  <style scoped>
  /* Add any custom styles here */
  th, td {
    text-align: center;
    cursor: pointer;
  }
  </style>