import { ChallengeBase } from "./Challenge"

export class Sub extends ChallengeBase {
    title = "sub - substraction"
    description = `Obviously Arm can also do substraction - and it essentially works the same as add!

    %sub r0, r1, r2%

    is equivalent to

    r0 = r1 - r2

    and

    %sub r0, r1%

    is equivalent to

    r0 = r0 - r1
`

    description_short = `Implement this formula: r0 = r3 - (r1 + r2)`
    prepare(emu) {
        console.log("Preparing challenge...")
        console.log(emu)
        emu.setRegister("R1", 0x123)
        emu.setRegister("R2", 0x234)
        emu.setRegister("R3", 0x456)
    }

    check(emu) {
        console.log("Checking for success")
        if(emu.getRegister("R0") == 0x456 - (0x123 + 0x234)) {
            return true;
        }
        return false;
    }

    template = `sub r0, r0, r0`

    registers_in = ["R1", "R2", "R3"]
    registers_out = ["R0"]

    score = {
        "3": {
            instructionCount: 2,
            executionSteps: 0 // TODO
        },
        "2": {
            instructionCount: 3,
            executionSteps: 3 // TODO
        },
    }

    memories = []
}
