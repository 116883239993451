// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.toast[data-v-496c03cb] {
    opacity: 0;
    transition: opacity 1s ease;
}
.toast.show[data-v-496c03cb] {
    opacity: 1;
    transition: opacity 1s ease;
}
`, "",{"version":3,"sources":["webpack://./src/components/ToastWidget.vue"],"names":[],"mappings":";AAoDA;IACI,UAAU;IACV,2BAA2B;AAC/B;AAEA;IACI,UAAU;IACV,2BAA2B;AAC/B","sourcesContent":["<template>\n    <div class=\"toast-container position-fixed bottom-0 end-0 p-3\" style=\"z-index: 1100;\">\n        \n        <div class=\"toast\" role=\"alert\" aria-live=\"assertive\" aria-atomic=\"true\" :class=\"{ show: visible }\" ref=\"toast\">\n            <div class=\"d-flex\">\n                <div class=\"toast-body\">\n                {{message}}\n                </div>\n                <button type=\"button\" class=\"btn-close btn-close-white me-2 m-auto\" data-bs-dismiss=\"toast\" aria-label=\"Close\"></button>\n            </div>\n        </div>\n    </div>\n</template>\n\n<script>\nexport default {\n    name: \"ToastWidget\",\n    props: {\n        autoHide: {\n            type: Boolean,\n            default: true,\n        },\n        delay: {\n            type: Number,\n            default: 5000, // 5 seconds\n        },\n    },\n    data() {\n        return {\n            visible: false,\n            message: \"\",\n        };\n    },\n    methods: {\n        showToast(message) {\n            this.message = message;\n            this.visible = true;\n\n            if (this.autoHide) {\n                setTimeout(() => {\n                    this.hideToast();\n                }, this.delay);\n            }\n        },\n        hideToast() {\n            this.visible = false;\n        },\n    },\n};\n</script>\n\n<style scoped>\n.toast {\n    opacity: 0;\n    transition: opacity 1s ease;\n}\n\n.toast.show {\n    opacity: 1;\n    transition: opacity 1s ease;\n}\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
