import { MovImmediate } from "./challenges/MovImmediate";
import { MovRegister } from "./challenges/MovRegister";
import { Add } from "./challenges/Add";
import { Sub } from './challenges/Sub';
import { Carry } from "./challenges/Carry";
import { ModifiedImmediate } from "./challenges/ModifiedImmediate";
import { Branch } from "./challenges/Branch";
import { Compare } from "./challenges/Compare";
import { ConditionalExecution } from "./challenges/ConditionalExecution";
import { ConditionalExecutionLTGT } from "./challenges/ConditionalExecutionLTGT";
import { BitwiseOperations } from "./challenges/BitwiseOperations";
import { BitwiseShifting } from "./challenges/BitwiseShifting";
const Challenges = [
    new MovImmediate(),
    new MovRegister(),
    new Add(),
    new Sub(),
    new ModifiedImmediate(),
    new BitwiseOperations(),
    new BitwiseShifting(),
    
    new Branch(),
    new Compare(),
    new ConditionalExecution(),
    new ConditionalExecutionLTGT(),
    
    // new Carry()
]
export default Challenges
