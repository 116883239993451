import { ChallengeBase } from "./Challenge"

export class Branch extends ChallengeBase {
    title = "Basic branching"
    description = `Now sometimes we want to jump to other code, and on Arm this is done with the Branch family of instructions, the most basic of which is the %B% instruction:

Branches in general can be relative or absolute: We can either branch relative to the current PC address, or to an absolute address that is stored in a register.

The B instruction only supports relative branches: We can jump either back relative to the current PC, or forward.

Generally we do not count our branch targets manually though, instead we use labels:

B bar
foo:
    mov r0, #1
    B end
bar:
    mov r0, #2
end:

Here the B instruction will directly jump to bar, setting r0 to #1. Labels in assembly are just a string, followed by a :. Labels do not take up any space, as they are just used by the assembler to calculate how far to jump using the B instructions.

Let's give B a try!
`

    description_short = `B to the label foo`
    prepare(emu) {
    }

    check(emu) {
        console.log("Checking for success")
        if(emu.getRegister("R0") == 31337) {
            return true;
        }
        return false;
    }

    template = `add r0, r0, r0`

    code_appendix = `
bar:
    mov r0, #0
    B end
foo:
    mov r0, #31337
end:
`


    score = {
        "3": {
            instructionCount: 2,
            executionSteps: 0 // TODO
        },
        "2": {
            instructionCount: 3,
            executionSteps: 3 // TODO
        },
    }

    memories = []
}
