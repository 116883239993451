<template>
    <div>
        <div>
            <table>
                <tr>
                    <th>R0</th>
                    <td>{{ regs[0] }}</td>
                    <th>R8</th>
                    <td>{{ regs[8] }}</td>
                </tr>
                <tr>
                    <th>R1</th>
                    <td>{{ regs[1] }}</td>
                    <th>R9</th>
                    <td>{{ regs[9] }}</td>
                </tr>
                <tr>
                    <th>R2</th>
                    <td>{{ regs[2] }}</td>
                    <th>R10</th>
                    <td>{{ regs[10] }}</td>
                </tr>
                <tr>
                    <th>R3</th>
                    <td>{{ regs[3] }}</td>
                    <th>R11</th>
                    <td>{{ regs[11] }}</td>
                </tr>
                <tr>
                    <th>R4</th>
                    <td>{{ regs[4] }}</td>
                    <th>R12</th>
                    <td>{{ regs[12] }}</td>
                </tr>
                <tr>
                    <th>R5</th>
                    <td>{{ regs[5] }}</td>
                    <th>SP</th>
                    <td>{{ regs[13] }}</td>
                </tr>
                <tr>
                    <th>R6</th>
                    <td>{{ regs[6] }}</td>
                    <th>LR</th>
                    <td>{{ regs[14] }}</td>
                </tr>
                <tr>
                    <th>R7</th>
                    <td>{{ regs[7] }}</td>
                    <th>PC</th>
                    <td>{{ regs[15] }}</td>
                </tr>
                <tr>
                    <th>APSR</th>
                    <td>{{ regs[16] }}</td>
                    <th>APSR_NZCV</th>
                    <td>{{ regs[17] }}</td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
import * as util from '../Util.js';
export default {
    props: ['emulator',],
    computed: {
        regs() {
            const regs = [
                '0x' + this.emulator.getRegister('R0').toString(16),
                '0x' + this.emulator.getRegister('R1').toString(16),
                '0x' + this.emulator.getRegister('R2').toString(16),
                '0x' + this.emulator.getRegister('R3').toString(16),
                '0x' + this.emulator.getRegister('R4').toString(16),
                '0x' + this.emulator.getRegister('R5').toString(16),
                '0x' + this.emulator.getRegister('R6').toString(16),
                '0x' + this.emulator.getRegister('R7').toString(16),
                '0x' + this.emulator.getRegister('R8').toString(16),
                '0x' + this.emulator.getRegister('R9').toString(16),
                '0x' + this.emulator.getRegister('R10').toString(16),
                '0x' + this.emulator.getRegister('R11').toString(16),
                '0x' + this.emulator.getRegister('R12').toString(16),
                '0x' + this.emulator.getRegister('SP').toString(16),
                '0x' + this.emulator.getRegister('LR').toString(16),
                '0x' + this.emulator.getRegister('PC').toString(16),
                '0x' + this.emulator.getRegister('CPSR').toString(16),
                '0x' + this.emulator.getRegister('CPSR_NZCV').toString(16),
            ]
            return regs;

            console.log("computed")
            // console.log(this.emulatorStep)
            const data = this.emulator.u.mem_read(this.address, this.length);
            const hex = util.hexdump(this.address, data)

            // You can customize the formatting here
            return hex;
            // return this.memoryContents;
        }
    }
}
</script>

<style scoped>
/* Component-specific styles */
th,
td {
    width: 50px;
    text-align: center;
    font-family: monospace;
}
</style>
