<template>
    <button @click="show = !show">Toggle</button>
    <Transition mode="out-in">
        <p v-if="show">hello</p>
        <p v-else>me</p>
    </Transition>
</template>

<script>
export default {
    data() {
        return {
            show: false
        }
    },
  computed: {
  },
  methods: {
  },
};
</script>

<style scoped>
/* Add styles if needed */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
