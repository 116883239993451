<template>
    <div class="toast-container position-fixed bottom-0 end-0 p-3" style="z-index: 1100;">
        
        <div class="toast" role="alert" aria-live="assertive" aria-atomic="true" :class="{ show: visible }" ref="toast">
            <div class="d-flex">
                <div class="toast-body">
                {{message}}
                </div>
                <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ToastWidget",
    props: {
        autoHide: {
            type: Boolean,
            default: true,
        },
        delay: {
            type: Number,
            default: 5000, // 5 seconds
        },
    },
    data() {
        return {
            visible: false,
            message: "",
        };
    },
    methods: {
        showToast(message) {
            this.message = message;
            this.visible = true;

            if (this.autoHide) {
                setTimeout(() => {
                    this.hideToast();
                }, this.delay);
            }
        },
        hideToast() {
            this.visible = false;
        },
    },
};
</script>

<style scoped>
.toast {
    opacity: 0;
    transition: opacity 1s ease;
}

.toast.show {
    opacity: 1;
    transition: opacity 1s ease;
}
</style>