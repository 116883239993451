export function uint8ArrayToUint32LE(uint8Array) {
    // Ensure the input is a Uint8Array with length 4
    if (uint8Array.length !== 4) {
        throw new Error("Input must be a 4-byte Uint8Array");
    }

    // Create a DataView on the buffer of the Uint8Array
    const dataView = new DataView(uint8Array.buffer, uint8Array.byteOffset, uint8Array.byteLength);

    // Use getUint32 with little-endian (true)
    return dataView.getUint32(0, true);
}

export function uint32ToUint8ArrayLE(uint32) {
    // Create a Uint8Array of length 4 (since 32 bits / 8 bits per byte = 4 bytes)
    const uint8Array = new Uint8Array(4);
    
    // Store the integer in little-endian format
    uint8Array[0] = uint32 & 0xFF;          // Get the least significant byte
    uint8Array[1] = (uint32 >> 8) & 0xFF;   // Get the second byte
    uint8Array[2] = (uint32 >> 16) & 0xFF;  // Get the third byte
    uint8Array[3] = (uint32 >> 24) & 0xFF;  // Get the most significant byte

    return uint8Array;
}

export function checkUint8ArrayForString(mem, expectedString) {
    // Convert the expected string to a Uint8Array
    const expectedArray = stringToUint8Array(expectedString);

    // Create a Uint8Array of length 100
    const expectedFullArray = new Uint8Array(100);

    // Copy the expectedArray into the beginning of expectedFullArray
    expectedFullArray.set(expectedArray, 0);

    // Compare the two arrays
    for (let i = 0; i < 100; i++) {
        if (mem[i] !== expectedFullArray[i]) {
            return false;
        }
    }
    return true;
}

export function stringToUint8Array(str) {
    // Create a Uint8Array with the same length as the string
    const uint8Array = new Uint8Array(str.length);

    // Iterate over the string and set each character's ASCII value in the array
    for (let i = 0; i < str.length; i++) {
        uint8Array[i] = str.charCodeAt(i);
    }

    return uint8Array;
}

export function uint8ArrayToString(uint8Array) {
    let str = '';

    for (let i = 0; i < uint8Array.length; i++) {
        // If the current element is 0, stop processing
        if (uint8Array[i] === 0) {
            break;
        }
        // Convert each byte to a character and accumulate the string
        str += String.fromCharCode(uint8Array[i]);
    }

    return str;
}

export function bytesToHexString(byteArray) {
    return byteArray.map(byte => {
        let hex = byte.toString(16).padStart(2, '0'); // Convert to hex and ensure it's 2 digits
        return `0x${hex}`;
    }).join(' ');
}

export function hexdump(address, byteArray) {
    const bytesPerLine = 8;
    let hexDump = '';

    for (let i = 0; i < byteArray.length; i += bytesPerLine) {
        // Address for the current line
        let lineAddress = (address + i).toString(16).padStart(4, '0');

        // Hex part
        let hexPart = '';
        let asciiPart = '';
        for (let j = 0; j < bytesPerLine; j++) {
            if (i + j < byteArray.length) {
                let byte = byteArray[i + j];
                hexPart += byte.toString(16).padStart(2, '0') + ' ';
                // ASCII part
                asciiPart += byte >= 0x20 && byte <= 0x7e ? String.fromCharCode(byte) : '.';
            } else {
                hexPart += '   '; // Padding for incomplete lines
                asciiPart += ' ';
            }
        }

        // Format the line
        hexDump += `0x${lineAddress} | ${hexPart}| ${asciiPart}\n`;
    }

    return hexDump;
}
