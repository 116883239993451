// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
table[data-v-fb23f3a0] {
    font-family: monospace;
}
  /* table {
    width: 100%;
    text-align: left;
  }
  
  td {
    padding: 8px;
  } */
  `, "",{"version":3,"sources":["webpack://./src/components/Registers.vue"],"names":[],"mappings":";AAmCE;IACE,sBAAsB;AACxB;EACA;;;;;;;KAOG","sourcesContent":["<template>\n    <div>\n      <table>\n        <tbody>\n          <tr v-for=\"(reg, index) in regs\" :key=\"index\">\n            <td>{{ reg }} = {{ formatHex(registerValues[reg] || 0) }}</td>\n            <td></td>\n          </tr>\n        </tbody>\n      </table>\n    </div>\n  </template>\n  \n  <script>\n  export default {\n    name: \"Registers\",\n    props: {\n      regs: {\n        type: Array,\n        required: true\n      },\n      registerValues: {\n        type: Object,\n        required: true\n      }\n    },\n    methods: {\n      formatHex(value) {\n        return '0x' + value.toString(16).toUpperCase(); // Convert to hex and prefix with '0x'\n      }\n    }\n  };\n  </script>\n  \n  <style scoped>\n  table {\n    font-family: monospace;\n  }\n  /* table {\n    width: 100%;\n    text-align: left;\n  }\n  \n  td {\n    padding: 8px;\n  } */\n  </style>\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
