<template>
    <div>
        <textarea :value="modelValue" @input="onInput" ref="textarea"></textarea>
    </div>
</template>
<script>
export default {
    name: 'CodeWidget',
    props: {
        modelValue: {
            type: String,
            required: true,
        },
    },
    methods: {
        onInput(event) {
            this.$emit('update:modelValue', event.target.value); // Emit the updated value to the parent
        },
        shakeTextarea() {
            const textarea = this.$refs.textarea;
            textarea.classList.add('shake-animation', 'red-text'); // Add shake and red background

            setTimeout(() => {
                textarea.classList.remove('shake-animation', 'red-text'); // Remove classes after 2 seconds
            }, 500);
        },
        greenTextarea() {
            const textarea = this.$refs.textarea;

            return new Promise((resolve, reject) => {
                textarea.classList.add('green-text'); // Add shake and red background
                setTimeout(() => {
                    resolve();
                }, 500);
            })
        },
    },
};
</script>
<style scoped>
/* Textarea styles */
textarea {
    width: 100%;
    background-color: RGBA(28, 32, 36);
    color: white;
    padding: 8px;
    box-sizing: border-box;
    font-family: monospace;
    resize: none;
    overflow: hidden;
    height: 15em;
    transition: color 0.3s ease;
}

.red-text {
    color: red !important;
}

.green-text {
    color: lightgreen !important;
}

/* Shake animation */
@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px);
    }

    50% {
        transform: translateX(5px);
    }

    75% {
        transform: translateX(-5px);
    }

    100% {
        transform: translateX(0);
    }
}

.shake-animation {
    animation: shake 0.5s ease;
    animation-iteration-count: 1;
}
</style>