import { ChallengeBase } from "./Challenge"

export class Compare extends ChallengeBase {
    title = "cmp-ring values..."
    description = `So far we only wrote completely linear code. Let's talk about changing up the execution flow!

We can compare the value of two registers using the %cmp% - or compare - instruction:

%
mov r0, #5
mov r1, #2
cmp r0, r1
%

In this case, we are comparing r0 (5) with r1 (2). Now the simplest comparison is checking whether those two are equal and taking a branch depending on whether they are equal or not.

We can do that using the BEQ - branch if equal - and the BNE - branch if not equal - instructions:

%
mov r0, #5
mov r1, #2
cmp r0, r1
BEQ r0_and_r1_are_equal
BNE r0_and_r1_are_not_equal

r0_and_r1_are_equal:
mov r2, #2
B end

r0_and_r1_are_not_equal:
mov r2, #1

end:

%

In this case, as r0 and r1 are not equal, the code will always jump to the label %r0_and_r1_are_not_equal%, and set r2 to 1. If we change the immedaites to be identical, then r2 would be set to 2.

Let's give this a try: I will give you some values in r0 and r1, and you branch to the label %equal% if they are equal, or to %not_equal% if they are not equal!
`
    show_flags = true
    description_short = `Compare r0 and r1. If they are equal, branch to equal, otherwise branch to not_equal.`
    prepare(emu) {
        console.log("Preparing challenge...")
        console.log(emu)
        emu.setRegister("R0", 1)
        emu.setRegister("R1", 2)
    }

    check(emu) {
        console.log("Checking for success")
        if(emu.getRegister("R0") == 1) {
            return true;
        }
        return false;
    }


    code_appendix = `
equal:
    mov r0, #0
    B end
not_equal:
    mov r0, #1
end:
`

    template = ``

    // registers_in = ["R1", "R2", "R3"]
    registers_out = ["R0"]

    score = {
        "3": {
            instructionCount: 3,
            executionSteps: 0 // TODO
        },
        "2": {
            instructionCount: 4,
            executionSteps: 3 // TODO
        },
    }

    memories = []
}
