<template>
    <span v-html="renderStars(stars)"></span>
</template>

<script>

import * as util from '../Util.js';
export default {

    name: "StarRenderer",
    props: {
        totalStars: {
            type: Number,
            default: 3
        },
        stars: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
        };
    },
    methods: {
        renderStars(stars) {
            let result = '';
            for (let i = 0; i < Math.floor(stars); i++) {
                result += '<i class="bi bi-star-fill"></i>';
            }
            if (stars % 1 !== 0) {
                result += '<i class="bi bi-star-half"></i>';
            }
            for (let i = Math.ceil(stars); i < this.totalStars; i++) {
                result += '<i class="bi bi-star"></i>';
            }
            return result;
        },
    },
}
</script>

<style scoped>
/* Component-specific styles */
</style>
