<template>
    <div>
      <table>
        <tbody>
          <tr v-for="(reg, index) in regs" :key="index">
            <td>{{ reg }} = {{ formatHex(registerValues[reg] || 0) }}</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </template>
  
  <script>
  export default {
    name: "Registers",
    props: {
      regs: {
        type: Array,
        required: true
      },
      registerValues: {
        type: Object,
        required: true
      }
    },
    methods: {
      formatHex(value) {
        return '0x' + value.toString(16).toUpperCase(); // Convert to hex and prefix with '0x'
      }
    }
  };
  </script>
  
  <style scoped>
  table {
    font-family: monospace;
  }
  /* table {
    width: 100%;
    text-align: left;
  }
  
  td {
    padding: 8px;
  } */
  </style>
  