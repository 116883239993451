<template>
    <div>
      <pre style="font-family: monospace;">{{ formattedMemory }}</pre>
    </div>
  </template>
  
  <script>
  import * as util from '../Util.js';
  export default {
    props: ['emulator', 'address', 'length', 'memoryContents'],
    computed: {
      formattedMemory() {
        console.log("computed")
        // console.log(this.emulatorStep)
        const data = this.emulator.read_data(this.address, this.length);
        const hex = util.hexdump(this.address, data)
        
        // You can customize the formatting here
        return hex;
        // return this.memoryContents;
      }
    }
  }
  </script>
  
  <style scoped>
  /* Component-specific styles */
  </style>
