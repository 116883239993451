import { ChallengeBase } from "./Challenge"

export class ModifiedImmediate extends ChallengeBase {
    title = "Modified immediates"
    description = `There is a maximum immediate value that can be provided to instructions like mov - and that has a very simple reason:

    Instructions on Arm have a length of 4-bytes/32-bits (or 2-bytes/4-bytes for Thumb instructions - we will get to that later), and in these 32-bits we need to encode both the instruction itself (MOV), the destination register, and the immediate value. This just leaves a couple of bits for the actual immediate value.

    For example for mov, we only have 16 bits, and so the maximum immediate value we can use is 65535 / 0xFFFF.

    However there's a twist: Modified immediate constants

    Modified immediate constants allow us to shift the (in most cases 8-bit) immediate value in 2-bit steps, so for example we can encode:

%
mov r0, 0x0000FF
mov r0, 0x000FF0
mov r0, 0x00FF00
mov r0, 0x0FF000
mov r0, 0xFF0000
%

But we can't encode %mov r0, 0x0FF001%!

However we can mix mov and add instructions to still create such values, so let's try to create:
0x0FF001
`

    description_short = `Set r0 to 0xFF001!`
    prepare(emu) {
        console.log("Preparing challenge...")
    }

    check(emu) {
        console.log("Checking for success")
        if(emu.getRegister("R0") == 0x0FF001) {
            return true;
        }
        return false;
    }

    template = `mov r0, #0`

    show_registers = false
    registers_out = ["R0"]

    score = {
        "3": {
            instructionCount: 2,
            executionSteps: 0 // TODO
        },
        "2": {
            instructionCount: 3,
            executionSteps: 3 // TODO
        },
    }

    memories = []
}
