<template>
    <BaseModal ref="modal">
      <template #header>
        <h5 class="modal-title">You did it!</h5>
      </template>
      <template #body>
        <div>
          <!-- Overall star count -->
          <h3 style="text-align: center;">
            <span v-html="renderStars(totalStars)"></span>
          </h3>
          <table>
            <!-- Number of instructions row -->
            <tr>
              <th>Number of instructions:</th>
              <td>
                <span v-html="renderStars(instructionCountStars)"></span>
              </td>
              <td>{{ stats.instructionCount }}</td>
            </tr>
            <!-- Execution steps row -->
            <tr>
              <th>Execution steps:</th>
              <td>
                <span v-html="renderStars(executionStepsStars)"></span>
              </td>
              <td>{{ stats.executionSteps }}</td>
            </tr>
          </table>
        </div>
      </template>
      <template #footer>
        <button type="button" class="btn btn-primary" @click="handleAction">Take Action</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </template>
    </BaseModal>
  </template>
  
  <script>
  import BaseModal from './BaseModal.vue'; // Adjust the path as needed
  
  export default {
    components: {
      BaseModal,
    },
    props: {
      challenge: {
        type: Object,
        required: true,
      },
      stats: {
        type: Object,
        required: true,
      },
    },
    computed: {
      // Compute stars for instruction count
      instructionCountStars() {
        const instructionCount = this.stats.instructionCount;
        if (instructionCount <= this.challenge.score["3"].instructionCount) return 3;
        if (instructionCount <= this.challenge.score["2"].instructionCount) return 2;
        return 1;
      },
      // Compute stars for execution steps
      executionStepsStars() {
        const executionSteps = this.stats.executionSteps;
        if (executionSteps <= this.challenge.score["3"].executionSteps) return 3;
        if (executionSteps <= this.challenge.score["2"].executionSteps) return 2;
        return 1;
      },
      // Calculate total stars based on both scores
      totalStars() {
        const total = (this.instructionCountStars + this.executionStepsStars) / 2;
        if (total === 3) return 3;
        if (total === 2.5) return 2.5;
        if (total === 2) return 2;
        if (total === 1.5) return 1.5;
        return 1;
      },
    },
    methods: {
      // Method to render stars dynamically
      renderStars(stars) {
        let result = '';
        for (let i = 0; i < Math.floor(stars); i++) {
          result += '<i class="bi bi-star-fill"></i>';
        }
        if (stars % 1 !== 0) {
          result += '<i class="bi bi-star-half"></i>';
        }
        for (let i = Math.ceil(stars); i < 3; i++) {
          result += '<i class="bi bi-star"></i>';
        }
        return result;
      },
      showModal() {
        this.$refs.modal.showModal();
      },
      closeModal() {
        this.$refs.modal.hideModal();
      },
      handleAction() {
        console.log('Action taken on statistics');
        this.closeModal();
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add styles if needed */
  </style>
  