import { ChallengeBase } from "./Challenge"

export class ConditionalExecutionLTGT extends ChallengeBase {
    title = "Conditional Execution with LT & GT"
    description = `In addition to conditions like EQ (Equal) and NE (Not Equal), ARM assembly provides other condition codes such as LT (Less Than) and GT (Greater Than) to compare numbers.

For example, you can write:

%
cmp r0, r1
mov r0, #3
movlt r0, #1
movgt r0, #2
%

This code compares r0 and r1 and:
- Sets r0 to 1 if r0 is less than r1.
- Sets r0 to 2 if r0 is greater than r1.
- Otherwise, sets r0 to 3.

Now let's try this: Write code that compares r0 and r1. If r0 is less than r1, set r0 to #1. If r0 is greater than r1, set r0 to #2. Otherwise, set r0 to #3!
`
    show_flags = true
    description_short = `Compare r0 and r1. If r0 < r1, set r0 to 1. If r0 > r1, set r0 to 2. Otherwise, set r0 to 3. Try using movlt, movgt, and movge!`
    prepare(emu) {
        console.log("Preparing challenge...")
        console.log(emu)
        emu.setRegister("R0", 5)
        emu.setRegister("R1", 3)
    }

    check(emu) {
        console.log("Checking for success")
        const result = emu.getRegister("R0");
        if (result == 2) {
            return true;
        }
        return false;
    }

    template = ``

    registers_out = ["R0"]

    score = {
        "3": {
            instructionCount: 4,
            executionSteps: 0 // TODO
        },
        "2": {
            instructionCount: 4,
            executionSteps: 3 // TODO
        },
    }

    memories = []
}
