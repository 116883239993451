// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  /* Component-specific styles */
  `, "",{"version":3,"sources":["webpack://./src/components/MemoryWidget.vue"],"names":[],"mappings":";EA0BE,8BAA8B","sourcesContent":["<template>\n    <div>\n      <pre style=\"font-family: monospace;\">{{ formattedMemory }}</pre>\n    </div>\n  </template>\n  \n  <script>\n  import * as util from '../Util.js';\n  export default {\n    props: ['emulator', 'address', 'length', 'memoryContents'],\n    computed: {\n      formattedMemory() {\n        console.log(\"computed\")\n        // console.log(this.emulatorStep)\n        const data = this.emulator.read_data(this.address, this.length);\n        const hex = util.hexdump(this.address, data)\n        \n        // You can customize the formatting here\n        return hex;\n        // return this.memoryContents;\n      }\n    }\n  }\n  </script>\n  \n  <style scoped>\n  /* Component-specific styles */\n  </style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
