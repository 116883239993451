<template>
    <transition
      name="modal-fade"
      @enter="enter"
      @leave="leave"
    >
      <div v-if="visible" class="modal" tabindex="-1" role="dialog" style="display: block;">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <slot name="header">
                <h5 class="modal-title">Modal title</h5>
              </slot>
              <button type="button" class="btn-close" @click="hideModal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <slot name="body">
                <p>Modal body content goes here.</p>
              </slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button type="button" class="btn btn-secondary" @click="hideModal">Close</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    data() {
      return {
        visible: false,
      };
    },
    methods: {
      showModal() {
        this.visible = true;
      },
      hideModal() {
        this.visible = false;
      },
      enter(el) {
        el.style.opacity = 0;
        el.style.transform = "scale(0.9)";
        setTimeout(() => {
          el.style.transition = "opacity 0.3s ease, transform 0.3s ease";
          el.style.opacity = 1;
          el.style.transform = "scale(1)";
        }, 10); // Small timeout to allow the styles to apply
      },
      leave(el) {
        el.style.transition = "opacity 0.3s ease, transform 0.3s ease";
        el.style.opacity = 0;
        el.style.transform = "scale(0.9)";
      }
    },
  };
  </script>
  
  <style scoped>
  .modal-fade-enter-active, .modal-fade-leave-active {
    transition: opacity 0.3s ease, transform 0.3s ease;
  }
  .modal-fade-enter, .modal-fade-leave-to {
    opacity: 0;
    transform: scale(0.9);
  }
  </style>
  