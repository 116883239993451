<template>
    <transition
      name="slide-vertical"
      @before-enter="beforeEnter"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
    >
      <div v-if="visible" :class="alertClass" role="alert" ref="alertBox">
        <slot></slot>
        <button type="button" class="close" @click="closeAlert">
          <span>&times;</span>
        </button>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    props: {
      type: {
        type: String,
        default: 'primary',
      },
      show: {
        type: Boolean,
        default: true,
      },
      autoClose: {
        type: Boolean,
        default: false,
      },
      autoCloseDelay: {
        type: Number,
        default: 3000, // 3 seconds
      },
    },
    data() {
      return {
        visible: this.show,
      };
    },
    computed: {
      alertClass() {
        return `alert alert-${this.type}`;
      },
    },
    methods: {
      closeAlert() {
        this.visible = false;
      },
      beforeEnter(el) {
        el.style.height = '0';
        el.style.opacity = '0';
      },
      enter(el) {
        el.style.transition = 'height 0.5s ease, opacity 0.5s ease';
        el.style.height = el.scrollHeight + 'px';
        el.style.opacity = '1';
      },
      afterEnter(el) {
        el.style.height = 'auto'; // Reset the height after the transition
      },
      leave(el) {
        el.style.transition = 'height 0.5s ease, opacity 0.5s ease';
        el.style.height = '0';
        el.style.opacity = '0';
      },
    },
    watch: {
      show(val) {
        this.visible = val;
      },
    },
    mounted() {
      if (this.autoClose) {
        setTimeout(() => {
          this.closeAlert();
        }, this.autoCloseDelay);
      }
    },
  };
  </script>
  
  <style scoped>
  .slide-vertical-enter-active,
  .slide-vertical-leave-active {
    overflow: hidden;
  }
  </style>
  