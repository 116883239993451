import * as util from './Util.js';


    // Hook for each instruction
    function hookCode(uc, address, size, user_data) {
        console.log(`Executed instruction at 0x${address.toString(16)}, size: ${size}`);
    }


export default class ArmEmulator {
    KS_ARCH = ks.ARCH_ARM;
    UC_ARCH = uc.ARCH_ARM;
    CS_ARCH = cs.ARCH_ARM;
    // Or MODE_MICRO
    KS_MODE = ks.MODE_ARM;
    UC_MODE = uc.MODE_ARM;
    CS_MODE = cs.MODE_ARM;

    constructor(base_address = 0x1000) {
        console.log("Emulator constructed")
        this.base_address = base_address;
        this.size = 4 * 1024;
        this.started = false;
        this.maps = []
        this.writes = []
        this.onupdate = undefined;
        this.full_reset()
    }

    map_memory(address, size) {
        this.maps.push([address, size])
        this.u.mem_map(address, size, uc.PROT_ALL);
    }
    
    createUnicornInstance() {

        if (!this.u) {
            this.u = new uc.Unicorn(this.UC_ARCH, this.UC_MODE);
            // Add the hook to the Unicorn instance
            this.u.hook_add(uc.HOOK_CODE, hookCode);
            
        }
    }

    full_reset() {
        this.createUnicornInstance();
        // console.log(this.u.mem_regions())
        for (var i = 0; i < this.maps.length; i++) {
            try {
                this.u.mem_unmap(this.maps[i][0], this.maps[i][1])
            } catch {

            }

        }
        this.maps = []
        this.writes = []
        this.map_memory(this.base_address, this.size)
        if (this.text) {
            this.load_assembly_program(this.text)
        }
        this.onupdate?.(this)
    }

    reset() {
        this.createUnicornInstance();

        for (var i = 0; i < this.maps.length; i++) {
            this.u.mem_unmap(this.maps[i][0], this.maps[i][1])
            this.u.mem_map(this.maps[i][0], this.maps[i][1], uc.PROT_ALL)
        }
        // this.map_memory(this.base_address, this.size)
        for (var i = 0; i < this.writes.length; i++) {
            this.u.mem_write(this.writes[i][0], this.writes[i][1])
        }

        if (this.text) {
            this.load_assembly_program(this.text)
        }
        this.onupdate?.(this)
    }

    print_registers() {
        var str = "";
        str += "PC=" + this.program_counter().toString(16) + " ";
        for (var i = 0; i < 8; i++) {
            str += "R" + i + "=" + this.getRegister("R" + i).toString(16) + " ";
        }
        console.log(str)
    }

    // Functino that gives the (arch-specific) program counter back.
    // PC on arm, IP on x86
    // TODO: Handle x64
    program_counter() {
        return this.u.reg_read_i32(uc.ARM_REG_PC);
    }

    getRegister(reg) {
        return util.uint8ArrayToUint32LE(this.u.reg_read(uc["ARM_REG_" + reg], 4));
    }

    getRegisters(regs) {
        const ret = {}
        for(var k in regs) {
            ret[regs[k]] = this.getRegister(regs[k])
        }
        return ret;
    }

    setRegister(reg, value) {
        const v = util.uint32ToUint8ArrayLE(value)
        console.log("Writing value")
        console.log(v)
        this.u.reg_write(uc["ARM_REG_" + reg], v)
        // return uint8ArrayToUint32LE(this.u.reg_read(uc["ARM_REG_" + reg], 4));
    }

    CPSR() {
        const cpsr = this.getRegister("CPSR");

        return {
            N: (cpsr >>> 31) & 1, // Negative flag (bit 31)
            Z: (cpsr >>> 30) & 1, // Zero flag (bit 30)
            C: (cpsr >>> 29) & 1, // Carry flag (bit 29)
            V: (cpsr >>> 28) & 1, // Overflow flag (bit 28)
            Q: (cpsr >>> 27) & 1, // Sticky overflow flag (bit 27)
            J: (cpsr >>> 24) & 1, // Jazelle state flag (bit 24)
            GE: (cpsr >>> 16) & 0xF, // Greater than or Equal flags (bits 19-16)
            E: (cpsr >>> 9) & 1, // Endianness flag (bit 9)
            A: (cpsr >>> 8) & 1, // Asynchronous abort mask (bit 8)
            I: (cpsr >>> 7) & 1, // IRQ disable flag (bit 7)
            F: (cpsr >>> 6) & 1, // FIQ disable flag (bit 6)
            T: (cpsr >>> 5) & 1, // Thumb state flag (bit 5)
            M: cpsr & 0x1F       // Processor mode (bits 4-0)
        };
    }

    // Fix PCs for example on thumb where the LSB has to be set
    fix_program_counter(program_counter) {
        if(this.UC_MODE == uc.MODE_ARM) {
            return program_counter;
        } else {
            return program_counter | 1;
        }
        
    }

    // const k = new ks.Keystone(this.KS_ARCH, this.KS_MODE);
    //     const assembled =  k.asm(assemblyText, this.base_address);
    //     const disassembled = this.disassemble(assembled, this.base_address);

    //     console.log(this.processAssembly(assemblyTextProcessed, disassembled));
    //     console.log(k);
    //     // console.log(r);
    //     console.log("disassemble");
    //     console.log(this.disassemble(assembled, this.base_address));
    //     return assembled;
    load_assembly_program(assemblyText, address = this.base_address) {
        console.log("Loading program:")
        console.log(assemblyText)
        this.text = assemblyText;
        this.disassembly = new Uint8Array()
        this.line_info = []

        const preprocessed = this.preprocessAssembly(assemblyText)
        const assembledProgram = this.assemble(assemblyText);
        this.assembledProgram = assembledProgram
        this.disassembly = this.disassemble(assembledProgram.mc)
        this.line_info = this.processAssembly(preprocessed, this.disassembly)

        console.log(this.assemble_lines(assemblyText));
        // Erase old program if user program
        if(address == this.base_address) {
            console.log("erasing")
            this.u.mem_write(address, new Uint8Array(this.size));
        } else {
            console.log("not erasing")
        }
        
        this.load_program(assembledProgram.mc, address);
        return true;
    }

    load_pure_assembly_program(address, assemblyText) {
        console.log("Loading pure program:")
        console.log(assemblyText)
        this.text = assemblyText;

        const assembledProgram = this.assemble(assemblyText).mc;
        this.load_program(assembledProgram, address);
        this.u.mem_write(address, assembledProgram)
    }


    load_program(code, address = this.base_address) {
        this.assembly = code;
        console.log("assembly is")
        console.log(this.assembly)
        console.log(this.assembledProgram)
        this.u.mem_write(address, this.assembly);
        this.started = false;
        // this.onupdate?.(this)
    }

    read_data(address, length) {
        return this.u.mem_read(address, length);
    }

    load_data(address, data) {
        console.log(`Mapping ${address} for ${data}`);

        // Calculate the start of the aligned memory region
        const aligned_start = Math.floor(address / 1024) * 1024;

        // Calculate the end of the memory region, ensuring it covers all of the data
        const aligned_end = Math.ceil((address + data.length) / 1024) * 1024;

        // Calculate the size of the memory region to map
        const map_size = aligned_end - aligned_start;

        try {
            // Map the aligned memory region
            this.map_memory(aligned_start, map_size)
        } catch { }

        // Write data to the original address
        this.u.mem_write(address, data);
        this.writes.push([address, data])
        this.onupdate?.(this)
    }

    run() {
        console.log("Running emu from ")
        console.log(this.fix_program_counter(this.base_address).toString(16))
        console.log("---")
        this.u.emu_start(this.fix_program_counter(this.base_address), this.base_address + this.assembly.length);
        this.print_registers();
        console.log("Ran, now calling on update")
        this.onupdate?.(this);
    }

    step() {
        console.log("STEP")
        console.log("this:")
        console.log(this)
        console.log(this.started)
        // Freh start
        if (this.started === false) {
            console.log("NEW START")
            // +1 specific for thumb...
            this.u.emu_start(this.fix_program_counter(this.base_address), this.base_address + this.assembly.length, 0, 1);
            this.print_registers();
            this.started = true;
        } else {
            console.log("Running continue")
            console.log(this.program_counter())
            this.u.emu_start(this.fix_program_counter(this.program_counter()), this.base_address + this.assembly.length, 0, 1);
            this.print_registers();
        }
    }

    processAssembly(assemblyText, disassemblyResult) {
        const result = [];

        // Track the current address to assign to instructions and labels
        let currentAddress = disassemblyResult[0].address;

        // Step 1: Iterate over the assembly lines
        assemblyText.forEach((line, index) => {
            line = line.trim();

            if (line === "") {
                // Handle empty lines
                result.push({
                    type: "empty",
                    address: currentAddress,
                });
            } else if (line.endsWith(':')) {
                // This line is a label
                const label = line.slice(0, -1);
                result.push({
                    type: "label",
                    label: label,
                    address: currentAddress
                });
            } else {
                // It's an instruction, so we find its corresponding disassembly result
                const disassembled = disassemblyResult.shift();

                if (disassembled) {
                    // Add the instruction info to the result array
                    result.push({
                        type: "instruction",
                        address: disassembled.address,
                        bytes: disassembled.bytes,
                        mnemonic: disassembled.mnemonic,
                        op_str: disassembled.op_str,
                    });

                    // Update the current address to the next one
                    currentAddress += disassembled.size;
                }
            }
        });

        return result;
    }


    preprocessAssembly(rawText) {
        const lines = rawText.split('\n'); // Split the text into lines
        const processedLines = lines.map(line => {
            // Remove leading and trailing spaces
            let cleanLine = line.trim();

            // Remove comments (anything after ;)
            const commentIndex = cleanLine.indexOf(';');
            if (commentIndex !== -1) {
                cleanLine = cleanLine.substring(0, commentIndex).trim();
            }

            return cleanLine;
        });

        // Filter out any empty lines that result from removing comments or spaces
        return processedLines;
    }

    assemble(assemblyText) {
        const assemblyTextProcessed = this.preprocessAssembly(assemblyText)
        const k = new ks.Keystone(this.KS_ARCH, this.KS_MODE);
        const assembled = k.asm(assemblyText, this.base_address);
        // console.log("Assembled")
        // console.log(assembled.count)
        // const disassembled = this.disassemble(assembled.mc, this.base_address);

        // console.log(this.processAssembly(assemblyTextProcessed, disassembled));
        // console.log(k);
        // // console.log(r);
        // console.log("disassemble");
        // console.log(this.disassemble(assembled, this.base_address));
        return assembled;
    }

    assembleInfo(assemblyText) {
        const assemblyTextProcessed = this.preprocessAssembly(assemblyText)
        const k = new ks.Keystone(this.KS_ARCH, this.KS_MODE);
        const assembled = k.asm(assemblyText, this.base_address);
        const disassembled = this.disassemble(assembled.mc, this.base_address);
        const processed = this.processAssembly(assemblyTextProcessed, disassembled);
        console.log("Assemble info:")
        console.log(processed)
        // console.log(this.processAssembly(assemblyTextProcessed, disassembled));
        const address_to_line = {};
        for(const lineno in processed) {
            console.log("line")
            const line = processed[lineno];
            if(line.type == "instruction") {
                address_to_line[line.address] = lineno;
            }
        }

        return address_to_line;
        
    }

    assemble_lines(assemblyText) {
        // const k = new ks.Keystone(this.KS_ARCH, this.KS_MODE);
        // return k.get_lines(assemblyCode);
    }

    disassemble(code) {
        const c = new cs.Capstone(this.CS_ARCH, this.CS_MODE);
        return c.disasm(code, this.base_address);
    }
}