import { ChallengeBase } from "./Challenge"

export class BitwiseOperations extends ChallengeBase {
    title = "Bitwise Operations with AND, ORR, and EOR"
    description = `In this challenge, we will explore three essential bitwise operations in ARM assembly: AND, ORR, and EOR.
    
AND
Performs a bitwise AND between two values, keeping only the bits that are 1 in both operands.

ORR
Performs a bitwise OR between two values, setting bits that are 1 in either operand.

EOR
Performs a bitwise XOR between two values, setting bits that are 1 in one operand but not both.

Here's an example:
%
mov r0, #0b1100    // r0 = 1100 in binary
mov r1, #0b1010    // r1 = 1010 in binary
and r2, r0, r1     // r2 = 1000 (bitwise AND)
orr r3, r0, r1     // r3 = 1110 (bitwise OR)
eor r4, r0, r1     // r4 = 0110 (bitwise XOR)
%

Now let's try this:

1. Use AND to mask out all but the lower 4 bits of r0.
2. Use ORR to set the 2nd bit of r1 (starting from 0).
3. Use EOR to XOR r0 and r1 into r2.`

    description_short = `Use AND to mask our all but the lower 4 bits of r0\n\nUse ORR to set the second bit in r1\n\nUse EOR to XOR r0 and r1 into r2.`
    show_flags = false

    prepare(emu) {
        console.log("Preparing challenge...");
        this.r0_initial = 0b10110110
        this.r1_initial = 0b00001100
        this.r2_initial = 0b10011010
        emu.setRegister("R0", 0b10110110) // example value for masking
        emu.setRegister("R1", 0b00001100) // example value for setting a bit
        emu.setRegister("R2", 0b10011010) // example value for toggling a bit
    }

    check(emu) {
        const r0 = emu.getRegister("R0");
        const r1 = emu.getRegister("R1");
        const r2 = emu.getRegister("R2");

        // Check if R0 has lower 4 bits masked (e.g., 0b00001110)
        const r0_expected = this.r0_initial & 0xF; // Lower 4 bits only
        
        // Check if R1 has the 2nd bit set
        const r1_expected = this.r1_initial | 0b00000010; // 2nd bit set
        
        // Check if R2 has the 3rd bit toggled
        const r2_expected = r0_expected ^ r1_expected; // Toggle 3rd bit
        
        return (r0 == r0_expected) && (r1 == r1_expected) && (r2 == r2_expected);
    }

    template = ``
    registers_in = ["R0", "R1", "R2"]
    registers_out = ["R0", "R1", "R2"]

    score = {
        "3": {
            instructionCount: 3,
            executionSteps: 0 // TODO
        },
        "2": {
            instructionCount: 4,
            executionSteps: 3 // TODO
        },
    }

    memories = []
}
