import { ChallengeBase } from "./Challenge"
export class MovImmediate extends ChallengeBase {
    title = "mov - immediate"
    description = `One of the most basic instructions is the MOV - or move - instruction.

With it we can copy a value into a register - either from another register, or from a constant that is encoded into the instruction.

We call these constants immediate, and so for example using %mov r0, #1% we can set the register r0 to the value '1'. Note the %#% that is used as a prefix here, this indciates the use of an immediate value.

We can also provide hex values as immedites, for that we just prefix them with 0x:

%mov r0, #0x10%

Your task in this level is very simple:

MOV the value '5' into r1!

`

    description_short = `move the value 5 into register r1!`
    prepare(emu) {
        console.log("Preparing challenge...")
    }

    check(emu) {
        console.log("Checking for success")
        if(emu.getRegister("R1") == 5) {
            return true;
        }
        return false;
    }

    template = `mov r0, #0`
    registers_out = ["R1"]
    score = {
        "3": {
            instructionCount: 1,
            executionSteps: 0 // TODO
        },
        "2": {
            instructionCount: 3,
            executionSteps: 3 // TODO
        },
    }

    memories = []
    show_registers = false;
}
